const doc = () => import(/* webpackChunkName: "docs.doc" */'@js/docs/doc.js')
if (document.querySelector('#doc')) {
	doc().then(e => {
		e.default()
	})
}

const inquiry = () => import(/* webpackChunkName: "docs.inquiry" */'@js/docs/inquiry.js')
if (document.querySelector('#inquiry')) {
	inquiry().then(e => {
		e.default()
	})
}

const tag = () => import(/* webpackChunkName: "docs.tag" */'@js/docs/tag.js')
if (document.querySelector('#tag')) {
	tag().then(e => {
		e.default()
	})
}
